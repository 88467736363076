import React from 'react';

export default function({children}){

//	otpauth-migration://offline?data=<span style={{color:'MediumSeaGreen'}}>{settings.example_values.data_parameter}</span>

	const styles	=	{
		pre	:	{
			color		:	'#ccc',
			fontFamily	:	'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
		},
		wrapper	:	{
			color		:	'#ccc',
			fontSize	:	'10px',
			fontWeight	:	'100',
		},
	};

	return (
		<div style={styles.wrapper}><span>Example:</span> <span style={styles.pre}>{children}</span></div>
	);
};