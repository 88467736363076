import React, { useState } from 'react';
import ProtocolBufferDecode from './ProtocolBufferDecode';
import ExampleText from '../../../components/ExampleText';
import settings from '../../../settings';

export default function({children,qrCodeData,setSecretBase64}){

	const [tabularData,setTabularData]	=	useState({
		algorithm	:	[],
		counter		:	[],
		digits		:	[],
		issuer		:	[],
		name		:	[],
		secret		:	[],
		type		:	[],
	});

	const styles=	{
		pre	:	{
			color	:	'Gold',
			margin	:	0,
		},
		header_column	:	{
			color			:	'#999',
			textTransform	:	'capitalize',
		},
	};

	const formSubmit = function(event){

		const form	=	event.target;
		const input	=	form.data_parameter;

		const string	=	input.value;

		event.preventDefault();
		event.stopPropagation();
		const Array_OtpParameters	=	ProtocolBufferDecode(string).otpParameters;
		console.log('Decoded Protocol Buffer',Array_OtpParameters);

		//	Iterate over parameter objects
		Array_OtpParameters.forEach(function(OtpParameters){

			//	Doing this will output enumerated values as defined by the ProtocolBuffer instead of the Integer representation.
			//	Note: It will encode the 'secret' as 'base64'... you'll have to stick it back into a Buffer to change the encoding later to 'base32'.
			OtpParameters	=	JSON.parse( JSON.stringify(OtpParameters));

			//	Iterate over parameter properties, assign value to restructured data object.
			Object.keys(tabularData).forEach(function(_key){
				const _value=	OtpParameters[_key];
				tabularData[_key].push(_value);
			});

		});

		setSecretBase64(tabularData.secret[0]);	//	Pass State to parent

		setTabularData(tabularData);

		return false;
	};

	function renderTableRow(key){
		return (
			<tr>
				<th scope="row" style={styles.header_column}>{key}</th>
				{tabularData[key].map(function(value){
					return (
						<td><pre style={styles.pre}>{value}</pre></td>
					);
				})}
			</tr>
		);
	}

    return (
        <div>
            <form onSubmit={formSubmit}>
                <div className="form-group">
                    <input name="data_parameter" type="text" className="form-control" placeholder="QR Code's 'data'" defaultValue={qrCodeData}/>
					<ExampleText>{settings.example_values.data_parameter}</ExampleText>
                </div>
                <button type="submit" className="btn btn-primary">Get Protocol Buffer</button>
            </form>
            <div style={{display:(tabularData.secret.length?'block':'none')}} >
				<hr />
                <table className="table table-dark">
                    <caption>Protocol Buffer Contents</caption>
					<thead>
						<tr>
							<th aria-label="Property Names"></th>
							{tabularData.secret.map(function(_value,_key){
								return (<th>Export #{+_key+1}</th>);
							})}
						</tr>
					</thead>
                    <tbody>
						{renderTableRow('algorithm')}
						{renderTableRow('counter')}
						{renderTableRow('digits')}
						{renderTableRow('issuer')}
						{renderTableRow('name')}
						{renderTableRow('secret')}
						{renderTableRow('type')}
                    </tbody>
                </table>
            </div>
        </div>
    );

};